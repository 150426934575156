import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './styles/index.scss'
import 'vant/es/toast/style';
import './permission.js'


// if (process.env.NODE_ENV === 'development') {
// eslint-disable-next-line
const { mockXHR } = require('../mock')
mockXHR()
// }
import Vant from 'vant';
import 'vant/lib/index.css';
const app = createApp(App)

let WebFileUrl='';
if (process.env.NODE_ENV === 'development') {
  WebFileUrl = 'https://51shanjian.oss-cn-shanghai.aliyuncs.com'
}else{
  WebFileUrl = 'http://meet-admin.91tests.net'
}
console.log(WebFileUrl)
app.provide('$WebFileUrl', WebFileUrl);
//
// createApp(App).use(router).mount('#app')

app.use(router).use(Vant).mount('#app')
console.log(process.env.NODE_ENV)
