import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/meeting-room-list'
  },
  {
    path: '/today',
    name: 'Today',
    component: () => import('@/views/today/index.vue'),
    meta: { title: '今日会议' }
  },
  {
    path: '/meeting-room-list',
    name: 'MeetingRoomList',
    component: () => import('@/views/meetingRoomList/index.vue'),
    meta: { title: '会议室列表' }
  },
  {
    path: '/detail',
    name: 'MeetingRoomDetail',
    component: () => import('@/views/meetingRoomDetail/index.vue'),
    meta: { title: '会议室详情' }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/index.vue'),
    meta: { title: '会议预定' }
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('@/views/mine/index.vue'),
    meta: { title: '我的' }
  },
  {
    path: '/participate',
    name: 'Participate',
    component: () => import('@/views/participate/index.vue'),
    meta: { title: '我参与的会议' }
  },
  {
    path: '/order-by',
    name: 'OrderBy',
    component: () => import('@/views/orderBy/index.vue'),
    meta: { title: '我预定的会议' }
  },
  {
    path: '/examine',
    name: 'Examine',
    component: () => import('@/views/examine/index.vue'),
    meta: { title: '我审批的会议' }
  },
  {
    path: '/examine-detail',
    name: 'ExamineDetail',
    component: () => import('@/views/ExamineDetail/index.vue'),
    meta: { title: '审批详情' }
  },
  {
    path: '/meeting-detail',
    name: 'MeetingDetail',
    component: () => import('@/views/MeetingDetail/index.vue'),
    meta: { title: '会议详情' }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/feedback/index.vue'),
    meta: { title: '意见反馈' }
  },
  {
    path: '/comment',
    name: 'Comment',
    component: () => import('@/views/comment/index.vue'),
    meta: { title: '会议评论' }
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/views/aboutUs/index.vue'),
    meta: { title: '关于我们' }
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('@/views/message/index.vue'),
    meta: { title: '我的消息' }
  },
  {
    path: '/message-detail',
    name: 'MessageDetail',
    component: () => import('@/views/messageDetail/index.vue'),
    meta: { title: '消息详情' }
  },
  {
    path: '/view',
    name: 'FileShow',
    component: () => import('@/views/view/index.vue'),
    meta: { title: '附件预览' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((form, to, next) => {
  // @ts-ignore
  document.title = to.meta.title ? to.meta.title : '微卡会议'
  next()
})
export default router
