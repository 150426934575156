import request from '@/utils/request'

export type GetInfoResponse = {
  name?: string
}

export function getUserInfo() {
  return request<GetInfoResponse>({
    url: '/meet/user/userInfo',
    method: 'post'
  })
}

export function getMeetingRoomList(params) {
  return request<GetInfoResponse>({
    url: `/meet/Index/getMeetByName?page=${params.page}&searchWords=${params.searchWords}`,
    method: 'get'
  })
}

export function getPartakeMeets(params) {
  return request<GetInfoResponse>({
    url: `/meet/Index/getPartakeMeets?page=${params.page}&keywords=${params.keywords}`,
    method: 'get'
  })
}

export function getDueMeets(params) {
  return request<GetInfoResponse>({
    url: `/meet/Index/getDueMeets?page=${params.page}&keywords=${params.keywords}`,
    method: 'get'
  })
}

export function getMeetInfo(id) {
  return request<GetInfoResponse>({
    url: `/meet/Index/getMeetInfo?id=${id}`,
    method: 'get'
  })
}


export function getOrderInfo(id,uuid) {
  return request<GetInfoResponse>({
    url: `/meet/Index/getMeetInfo?id=${id}&uuid=${uuid}`,
    method: 'get'
  })
}

export function meetdel(id) {
  return request<GetInfoResponse>({
    url: `/meet/Index/meetdel?id=${id}`,
    method: 'delete'
  })
}

export function getDate() {
  return request<GetInfoResponse>({
    url: `/meet/Index/getDate`,
    method: 'get'
  })
}

export function getDateList(params) {
  return request<GetInfoResponse>({
    url: `/meet/Index/getDateList?meet_id=${params.meet_id}&searchData=${params.searchData}`,
    method: 'get'
  })
}

//利用微笑授权code获取用户信息
export function getUserInfoWithCode(data) {
  return request<GetInfoResponse>({
    url: `/meet/User/getToken`,
    method: 'post',
    data
  })
}

export function createFeedBack(data: any) {
  return request({
    url: `/meet/FeedBack/createFeedBack`,
    method: 'post',
    data
  })
}


export function handleApply(data: any) {
  return request({
    url: `/meet/meeting/handleApply`,
    method: 'post',
    data
  })
}

export function getCommentList(id) {
  return request<GetInfoResponse>({
    url: `/meet/Comments/getList?order_id=${id}`,
    method: 'get'
  })
}

export function createComments(data: any) {
  return request<GetInfoResponse>({
    url: `/meet/Comments/createComments`,
    method: 'post',
    data
  })
}



