module.exports = {
  development: {
    baseURL: '/dev-api',
    mockURL: 'http://192.168.8.160:85/'
  },
  production: {
    baseURL: '/prod-api',
    mockURL: 'http://meet-admin.91tests.net/'
  }
}
